import {createRouter, createWebHashHistory} from 'vue-router'
import Index from '../views/index'
import RPage from '../views/rpage'
import NPage from '../views/npage'
import Dr from '../views/denseRoll'

let rt = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: { transition: 'fade' },
  },
  {
    path: '/r',
    name: 'NPage',
    component: RPage,
    meta: { transition: 'fade' },
  },
  {
    path: '/n',
    name: 'RPage',
    component: NPage,
    meta: { transition: 'fade' },
  },
  {
    path: '/dr',
    name: 'denseRoll',
    component: Dr,
    meta: { transition: 'fade' },
  }
]

const router = createRouter({
  routes: rt,
  history: createWebHashHistory()
})

router.beforeEach((to, from) => {
  var routlist = []
  rt.forEach(item => {
    routlist.push(item.path)
  })
  routlist.forEach((item, index) => {
    if (from.path == item) {
      if (routlist.splice(index).includes(to.path)) {
        to.meta.transition = "fade"
      } else {
        to.meta.transition = "defa"
      }
    }
  })
})

export default router;
