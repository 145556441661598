<template>
  <container type="n">
    <div class="row is-r">
      <!-- 声明一个具名插槽 -->
      <img class="row-img" src="../assets/img/n-back.png" alt="">

      <img class="row-btn" @click="handleAlert" src="../assets/img/n-btn.png" alt="">
    </div>
  </container>
</template>
  
<script>
  import container from '../components/index'
  export default {
    name: 'Index',
    components: {container},
    data () {
      return {
      }
    },
    methods:{
      handleAlert(){
        this.$router.push('/dr?id=2');
      }
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .row{
      .row-img{
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100vh;
      }

      &.is-r{
        position: relative;

        .row-btn{
          display: block;
          width: 298px;
          position: absolute;
          bottom: 50px;
          right: 60px;
          cursor: pointer;
          transition: all 0.3s linear;

          &:hover{
            transform: scale(1.04);
          }
        }
      }
    }
</style>
