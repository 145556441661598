<template>
    <container :type="id == 1 ? 'r' : 'n'">
      <div  class="row is-index">

        <div class="dr-row">
            <div class="dr-filter">
                <div class="filter-title">筛选</div>
                <div class="filter-title-1" v-if="id == 1">
                    耀级悬赏令
                    <el-switch
                        v-model="query.gold_reward"
                        @change="e => handleChange(e, 'gold_reward')"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                </div>
                <div class="filter-title-2">
                    <input type="text" v-model="query.num" placeholder="悬赏号查找..." @input="e => handleChange(e, 'num')">
                </div>
                <div class="filter-cate">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item 
                            v-for="(item, idx) in cateList" 
                            :title="item.name" 
                            :name="idx"
                            :key="idx"
                        >
                            <div class="collapse-row">
                                <div class="collapse-item" v-for="(childItem, childIdx) in item.child" :key="childIdx">
                                    <el-checkbox v-model="childItem.checked">{{ childItem.name }}（{{childItem.num}}）</el-checkbox> 
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div class="dr-content"> 
                <div class="content-title">
                    <span class="tag">{{ id == 1 ? '釰' : '傩' }}</span>
                    <div class="right">
                        <span class="right-title">{{ id == 1 ? '悬赏令' : '' }}</span>
                        <div class="num">
                            <span class="number">{{total}}</span>
                            <div class="reset" @click="getPartList"><img src="../assets/img/reset.png" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="content-filter">
                    <div class="content-filter-title">
                        筛选
                        <span class="num">{{ cate_ids.length }}</span>
                    </div>
                    <div class="content-filter-box" v-if="cate_ids.length">
                        <span v-for="(item, idx) in cate_ids" :key="idx" @click="() => handleDelete(item)">{{ item.name }} X</span>
                        <span @click="() => handleDelete({}, 'all')" class="is-clear">清除</span>
                    </div>
                </div>
                
                <div class="content-list" v-loading="loading">
                    <div class="list-item" v-for="(item, idx) in list" :key="idx" @click="() => handleItem(item)">
                        <div class="item-img">
                            <el-image
                                :src="item.img_url"
                                :lazy="true">
                            </el-image>
                        </div>
                        <div class="item-title">
                            <span v-if="item.name">{{item.name}}</span>
                            <span>{{item.num}}#</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dr-row-model" :class="{'active': isShow}" @click.stop="() => isShow = false">
            <div class="model-box">
                <div class="box-left">
                    <img :src="detailsInfo && detailsInfo.img_url" alt="">
                </div>
                <div class="box-right">
                    <div class="right-title">
                        <span>{{detailsInfo && detailsInfo.name}}</span>
                        <span>{{detailsInfo && detailsInfo.num}}#</span>

                        <span class="ss" @click.stop="() => isQdShow = true">商市</span>
                    </div>
                    <div class="right-box" v-if="detailsInfo">
                        <div class="box-item" v-for="(item, idx) in detailsInfo.cate_list" :key="idx">
                            <span class="item-1">{{item.split('：')[0]}}</span>
                            <span class="item-2">{{item.split('：')[1]}}</span>
                        </div>
                    </div>

                    <p>* 括号内为含有该部件的NFT的占比</p>
                </div>
            </div>
        </div>
      </div>

      <div class="model" :class="{'active': isQdShow}" @click="isQdShow = false;">
        <img src="../assets/img/qidai.png" alt="">
      </div> 
    </container>
  </template>
    
  <script>
    import {getCateList, getPartList, getPartDetail} from '@/api/index'
    import container from '../components/index'
    export default {
      name: 'Index',
      components: {container},
      data () {
        return {
            id: 1,
            value: false,
            input: '',
            activeNames: ['1'],
            cateList: [],
            list: [],
            total: 0,
            checked: false,
            cate_ids: [],
            query: {
                cate_id_3: '',
                page: 1,
                page_size: 100,
                num: '',
                gold_reward: false,
                pid: 1
            },
            flag: true,
            loading: false,
            
            isShow: false,
            isQdShow: false,
            detailsInfo: null
        }
      },
      methods: {
        handleChange(e, type) {
            if(type == 'gold_reward' || type == 'num'){
                this.query.page = 1;
                this.list = [];
                this.getPartList();
            }
        },
        handleDelete(clickItem, type){
            if(type == 'all'){
                let cateList = JSON.parse(JSON.stringify(this.cateList));
                cateList.forEach(item => {
                    item.child && item.child.forEach(childItem => {
                        childItem.checked = false;
                    })
                });
                this.cateList = cateList;
            }else{
                let cateList = JSON.parse(JSON.stringify(this.cateList));
                cateList.forEach(item => {
                    item.child && item.child.forEach(childItem => {
                        if(childItem.id == clickItem.id){
                            childItem.checked = false;
                        }
                    })
                });
                this.cateList = cateList;
            }
        },
        // 获取内容
        async getPartList(){
            this.loading = true;
            this.query.pid = this.id;
            let res = await getPartList(this.query);
            this.loading = false;
            if(res.code == 0){
                this.list = [...this.list, ...res.data.list];
                this.total = res.data.total;

                if (this.total > this.list.length) {
                    //如果还有更多重新设置flag为true
                    this.flag = true
                } else {
                    //没有更多flag设置为false不在执行触底加载
                    this.flag = false
                }
            }
        },

        handleItem(item){
            getPartDetail({id: item.id}).then(res => {
                if(res.code == 0){
                    this.detailsInfo = res.data;
                    this.isShow = true;
                }else{
                    this.$message.error(res.msg);
                }
            });
        }
      },
      watch:{
        'cateList':{
            handler(newVal, oldVal) {
                let cate_id_3 = '';
                let cate_ids = [];
                newVal.forEach(item => {
                    item.child.forEach(childItem => {
                        if(childItem.checked){
                            cate_id_3 += childItem.id + ',';
                            cate_ids.push(childItem);
                        }
                    })
                })
                this.query.cate_id_3 = cate_id_3;
                this.cate_ids = cate_ids;
                this.query.page = 1;
                this.list = [];
                this.getPartList();
            },
            deep: true
        }
      },
      mounted(){
        let _this = this;
        this.$nextTick (() => {
            setTimeout(() => {
                let el_container = document.querySelector('.container');
                // 监听滚动事件
                el_container.addEventListener("scroll", () => { // 监听浏览器窗口滚动
                    if(!_this.flag || _this.loading)
                        return;
                    const General = document.querySelector(".dr-row"); // 获取ul
                    if (General.getBoundingClientRect().bottom < document.documentElement.clientHeight + 100) { // 当页面主元素底部到浏览器顶部高度位置小于浏览器总高度
                        _this.query.page++;
                        _this.getPartList();
                    }
                })
            }, 1000);
        })
      },
      created () {  
        let id = this.$route.query.id;
        if(id){
            this.id = id;
        }

        getCateList().then(res => {
          if(res.code == 0){
            let list = res.data.list[this.id-1] ? res.data.list[this.id-1] : res.data.list[0];
            list.child.forEach(item => {
                item.child && item.child.forEach(childItem => {
                    childItem.checked = false;
                })
            })

            this.cateList = list.child
          }
        });
      }
    }
  </script>
    
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    .container{
        background-image: url('../assets/img/b-back.jpg');
        background-position: center;
        background-size: contain;
        overflow-y: scroll;
    }

    .dr-row{
        max-width: 1640px;
        min-width: 1000px;
        width: 90%;
        height: auto;
        margin: 0 auto;
        display: flex;
        position: relative;
        z-index: 2;
        margin-top: 160px;

        .dr-filter{
            width: 25%;
            height: 100vh;
            background: #e9e9e9;
            margin-right: 40px;
            border-radius: 8px;
            padding: 25px;
            overflow-y: scroll;
            position: sticky;
            top: 0;

            &::-webkit-scrollbar{
                display: none;
                opacity: 0;
                width: 0;
            }

            .filter-title{
                font-size: 42px;
                color: #000;
                font-weight: bold;
                width: 100%;
                border-bottom: 2px solid #999;
            }

            .filter-title-1{
                display: flex;
                align-items: center;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                border-bottom: 1px solid #ccc;
                padding-left: 10px;
                color: red;
                font-weight: bold;
                font-size: 24px;
            }

            .filter-title-2{
                height: 46px;
                border-bottom: 1px solid #ccc;

                input{
                    display: block;
                    background: transparent;
                    width: 100%;
                    height: 100%;
                    padding-left: 10px;
                    font-size: 18px;
                    outline: none;
                    border: none;

                    &::placeholder {
                        color: #818181;
                        font-size: 18px;
                    }
                }
            }

            .collapse-row{
                padding-top: 12px;
                .collapse-item{
                    padding-left: 10px;
                }
            }

        }

        .dr-content{
            flex: 1;

            .content-title{
                display: flex;
                align-items: center;

                .tag{
                    display: block;
                    width: 125px;
                    height: 70px;
                    background: #a61415;
                    border-radius: 6px;
                    font-size: 40px;
                    color: #fff;
                    text-align: center;
                    line-height: 70px;
                }

                .right{
                    height: 80px;
                    display: flex;
                    align-items: center;
                    border-bottom: 2px solid #999;
                    flex: 1;
                    justify-content: space-between;
                    margin-left: 30px;

                    .right-title{
                        font-size: 40px;
                        color: #000;
                        font-weight: bold;
                    }

                    .num{
                        display: flex;
                        align-items: center;
                        font-size: 34px;
                        color: #3f3f3f;
                    }

                    .reset{
                        width: 46px;
                        height: 46px;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #b8b8b8;
                        margin-left: 20px;
                        cursor: pointer;

                        img{
                            display: block;
                            width: 20px;
                        }
                    }
                }
            }

            .content-filter{
                overflow: hidden;
                margin-top: 20px;

                .content-filter-title{
                    display: block;
                    float: left;
                    font-size: 20px;
                    color: #393939;
                    display: flex;
                    align-items: center;
                    font-weight: bold;

                    .num{
                        display: block;
                        width: 24px;
                        height: 24px;
                        float: left;
                        font-size: 18px;
                        margin-left: 10px;
                        text-align: center;
                        line-height: 24px;
                        color: #393939;
                        background: #b8b8b8;
                        border-radius: 4px;
                        font-weight: bold;
                    }
                }

                .content-filter-box{
                    float: left;
                    margin-left: 20px; 
                    
                    span{
                        display: block;
                        height: 30px;
                        line-height: 30px;
                        padding: 0 10px;
                        font-size: 16px;
                        color: #535353;
                        background: #fff;
                        border-radius: 8px;
                        text-align: center;
                        float: left;
                        margin-right: 10px;
                        cursor: pointer;

                        &.is-clear{
                            background: #a61415;
                            color: #fff;
                        }
                    }
                }
            }

            .content-list{
                margin-top: 20px;
                display: grid;
                grid-column-gap: 24px;
                column-gap: 24px;
                box-sizing: border-box;
                background-color: transparent;
                grid-template-columns: repeat(5,1fr);

                .list-item{
                    margin-bottom: 24px;
                    position: relative;
                    cursor: pointer;
                    transition: all 0.2s linear;
                    
                    .item-img{
                        display: block;
                        width: 100%;
                        border-radius: 10px;
                        overflow: hidden;
                        aspect-ratio: 1/1;
                        box-shadow: 0 1px 1px hsl(0deg 0% 0%/.075), 0 2px 2px hsl(0deg 0% 0%/.075), 0 4px 4px hsl(0deg 0% 0%/.075), 0 8px 8px hsl(0deg 0% 0%/.075), 0 16px 16px hsl(0deg 0% 0%/.075);

                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .item-title{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-top: 20px;

                        span:nth-child(1){
                            color: #7c7c7c;
                            font-size: 20px;
                            font-weight: bold;
                            text-align: center;
                        }

                        span:nth-child(2){
                            color: #393939;
                            font-size: 18px;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .dr-row-model{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        transition: all 0.3s linear;
        opacity: 0;
        z-index: -1;
        background: rgba(0,0,0,0.5);

        &.active{
            opacity: 1;
            z-index: 100;
        }

        .model-box{
            width: 1000px;
            height: 460px;
            background: #dbdbdb;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
            display: flex;

            .box-left{
                width: 460px;
                height: 460px;
                display: flex;
                align-items: center;
                overflow: hidden;
                border-radius: 10px 0 0 10px;

                img{
                    display: block;
                    width: 100%;
                }
            }

            .right-box{
                display: grid;
                grid-column-gap: 12px;
                column-gap: 12px;
                box-sizing: border-box;
                background-color: transparent;
                grid-template-columns: repeat(3,1fr);
                margin-top: 20px;
                max-height: calc(100% - 100px);
                overflow-y: scroll;

                &::-webkit-scrollbar{
                    display: none;
                }

                .box-item{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 10px;
                    border-radius: 8px;
                    background-color: #bebebe;
                    height: 50px;
                    margin-bottom: 12px;

                    .item-1{
                        color: #696969;
                        font-size: 14px;
                    }

                    .item-2{
                        color: #393939;
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }

            .box-right{
                flex: 1;
                height: 100%;
                position: relative;
                padding: 20px;

                .right-title{
                    width: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    span:nth-child(1){
                        font-size: 16px;
                        color: #7c7c7c;
                        font-weight: bold;
                    }
                    span:nth-child(2){
                        font-size: 30px;
                        color: #393939;
                        font-weight: bold;
                    }
                    span:nth-child(3){
                        font-size: 24px;
                        color: #fff;
                        font-weight: bold;
                        display: block;
                        width: 80px;
                        height: 40px;
                        background: #a61415;
                        border-radius: 6px;
                        position: absolute;
                        text-align: center;
                        line-height: 40px;
                        right: 0;
                        cursor: pointer;
                    }

                }

                p{
                    position: absolute;
                    left: 30px;
                    font-size: 14px;
                    color: #7c7c7c;
                    bottom: 30px;
                }
            }

        }

    }

    @media screen and (max-width: 1340px){
        .content-list {
            grid-template-columns: repeat(4,1fr) !important;
        }
    }
  </style>
  