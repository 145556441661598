<template>
   <router-view v-slot="{ Component }">
      <transition name="fade">
          <component :is="Component" />
      </transition>
    </router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import './assets/css/main.scss';

@media screen and (max-width: 900px){
  #app{
    background-image: url('./assets/img/h5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .container{
    opacity: 0;
    transition: all 0.2s linear;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s linear;
}
 
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.el-collapse-item.is-active{
  .el-collapse-item__header{
    border-bottom: none !important;
  }
}

.el-collapse-item__header{
  height: 46px !important;
  border-bottom: 1px solid #ccc !important;
  background: transparent !important;
  color: #1b1b1b !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding-left: 10px !important;
}

.el-collapse-item__wrap{
  background: transparent !important;
  border-bottom: 1px solid #ccc !important;
}

.el-checkbox__label{
    font-size: 14px;
    color: #000;
}


.el-checkbox.is-checked{

  .el-checkbox__inner{
    background-color: #a61415 !important;
    border-color: #a61415 !important;
  }

  .el-checkbox__label{
    color: #000 !important;
  }
}
</style>
