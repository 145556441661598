import api from './request'

export const getCateList = () => {
    return api.get('/api/secret/getCateList')
}

export const getPartList = (data) => {
    return api.get(`/api/secret/getPartList?pid=${data.pid}&cate_id_3=${data.cate_id_3}&page=${data.page}&page_size=${data.page_size}&num=${data.num}&gold_reward=${data.gold_reward}`);
}

export const getPartDetail = (data) => {
    return api.get(`/api/secret/getPartDetail?id=${data.id}`, data);
}

