<template>
    <div class="container">
        <div class="header">
            <a href="/">
              <img src="../assets/img/logo.png" alt="">
            </a>

            <div class="header-row">
                <li @click="() => handlePush(item)" :class="{'active': type == item.type}" v-for="(item,idx) in list" :key="idx">{{ item.name }}</li>
                <li @click="handleEwm"><img src="../assets/img/new-wx.png" alt=""></li>
                <!-- <li><img src="../assets/img/new-wb.png" alt=""></li> -->
                <!-- <li><img src="../assets/img/new-xhs.png" alt=""></li> -->
            </div>
        </div>

        <slot></slot>

        <div class="model" :class="{'active': isShow}" @click="isShow = false;">
          <div class="ewm" v-show="isType == 1">
            <img src="../assets/img/ewm.png" alt="">
            <span>微信扫码关注</span>
          </div>
          <img v-show="isType == 2" src="../assets/img/qidai.png" alt="">
        </div>  
    </div>
</template>

<script>
import { defineComponent } from 'vue';
  export default defineComponent({
    props: ['type'],
    name: 'Container',
    data () {
      console.log(this.type)
      return {
        list: [
            {type: 'index', name: '首页', path: '/'},
            {type: 'r', name: '釰', path: '/r'},
            {type: 'n', name: '傩', path: '/n'},
            // {type: 'g', name: '关于我们', path: '/'},
            {type: 's', name: '连接', path: ''},
        ],
        isShow: false,
        isType: 1
      }
    },
    methods:{
      handlePush(item){
        if(item.path == ''){
          this.isType = 2;
          this.isShow = true;
          return;
        }
        this.$router.push(item.path);
      },
      handleEwm(){
        this.isType = 1;
        this.isShow = true;
      }
    }
  })
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .container{
        width: 100%;
        height: 100vh;
        background-image: url("@/assets/img/bg.jpg");
        background-size: cover;
        position: relative;
        overflow: hidden;

        .header{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 50px;
          padding-top: 55px;

          a > img{
            display: block;
            width: 130px;
            transition: all 0.3s linear;
            cursor: pointer;

            &:hover{
              transform: scale(1.2);
            }
          }
        }

        .header-row{
          display: flex;

          /* 新框架 */
          li{
            display: block;
            width: auto;
            height: 40px;
            background: #e2e2e2;
            border-radius: 8px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #000;
            font-weight: bold;
            margin-left: 12px;
            padding: 0 30px;
            transition: all 0.3s linear;

            &:hover{
              background: #c7c7c7;
            }

            img{
              display: block;
              width: 30px;
            }

            &.active{
              background: #c7c7c7;
              color: #000;
            }
          }
        }

        .row{
          width: 100%;
          height: 100%;
        }
    }

    .model{
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: fixed;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s linear;
      opacity: 0;
      z-index: -1;

      .ewm{
        display: flex;
        flex-direction: column;
        align-items: center;

        img{
          display: block;
          width: 200px;
          margin-bottom: 20px;
        }

        span{
          color: #fff;
        }
      }

      &.active{
        z-index: 100;
        opacity: 1;
      }
    }
</style>