<template>
  <container type="index">
    <div class="row is-index">
      <!-- 声明一个具名插槽 -->
      <img class="row-img" src="../assets/img/index-back.png" alt="">

      <img class="row-wechat" src="../assets/img/wechat.png" alt="">
    </div>
  </container>
</template>
  
<script>
  import container from '../components/index'
  export default {
    name: 'Index',
    components: {container},
    data () {
      return {
        
      }
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .row{
      .row-img{
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100vh;
      }

      &.is-index{
        position: relative;

        .row-wechat{
          display: block;
          width: 280px;
          position: absolute;
          bottom: 50px;
          left: 50px;
        }
      }
    }

</style>
